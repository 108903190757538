<template>
	<div id="fundsTransfer" class="input-heading">
		<h3>{{ languageStrings.cashOutPlayer }}</h3>
		<div class="input-section">
			<div>
				<p>
					{{ languageStrings.maxCashOut }}:
					{{ setMaxCashout(cashierState.currentBank.currentBalanceCOC, systemMinorOrFull) }}
				</p>
				<div>
					<input
						hidden
						type="text"
						name="regularAU"
						@click="numInputPadInit('regularAU')"
						v-model="regularAU"
						placeholder="From Player Cash"
						autocomplete="off"
						onfocus="blur();"
					/>
					<input
						type="text"
						name="displayCOC"
						@click="numInputPadInit('regularAU')"
						v-model="displayCOC"
						placeholder="From Player Cash"
						autocomplete="off"
						onfocus="blur();"
					/>
				</div>
			</div>

			<button class="transfer btn" type="button" @click="fundsTransfer()" title="Cash Out">{{ languageStrings.cashOutText }}</button>
			<button
				v-if="transactionReport.success && portExists"
				class="reprint btn"
				type="button"
				@click="printTransactionReceipt(transactionReport)"
				title="Reprint Last Receipt"
			>
				{{ languageStrings.reprintLast }} {{ languageStrings.receipt }}
			</button>

			<table v-if="transactionReport.success" class="transactionReport">
				<tr class="header-row" rowspan="2">
					<th v-if="cashierState.transferFundsIn" colspan="2">Transferred to {{ cashierState.playerAccount.user.displayName }}</th>
					<th v-else colspan="2">{{ languageStrings.cashOutFrom }} {{ cashierState.playerAccount.user.displayName }}</th>
				</tr>
				<tr class="data-row" v-if="transactionReport.transferRegularCOC != 0">
					<th>{{ languageStrings.cashText }}</th>
					<td>
						{{ systemCurrencyTool.formatCurrency(Math.abs(transactionReport.transferRegularCOC), systemMinorOrFull) }}
					</td>
				</tr>
				<tr class="data-row" v-if="transactionReport.transferPromoAU != 0">
					<th>{{ languageStrings.Promo }}</th>
					<td>{{ systemCurrencyTool.formatFromAU(transactionReport.transferPromoAU, systemMinorOrFull) }}</td>
				</tr>
				<tr class="header-row" rowspan="2">
					<th colspan="2">{{ cashierState.playerAccount.playerDisplayName }} {{ languageStrings.balanceText }}</th>
				</tr>
				<tr class="data-row">
					<th>{{ languageStrings.regular }}</th>
					<td>{{ inGameCurrencyTool.formatFromAU(transactionReport.playerRegularAU, inGameMinorWholeOrFull) }}</td>
				</tr>
				<tr class="data-row">
					<th>{{ languageStrings.Promo }}</th>
					<td>{{ inGameCurrencyTool.formatFromAU(transactionReport.playerPromoAU, inGameMinorWholeOrFull) }}</td>
				</tr>
			</table>
		</div>
	</div>
	<transition name="fade">
		<NumInputPad
			v-if="inputConfig.inputField"
			:inputConfig="inputConfig"
			:currencyInfo="cashierState.currencyInfo"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</transition>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "FundsTransfer",
	inheritAttrs: false,
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		portExists: {
			type: Boolean,
		},
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		NumInputPad,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			inGameMinorWholeOrFull: this.inGameCurrencyTool.displayType.minorWholeOrFull,
			playerAccount: {
				account: {},
				buyInOffers: [],
				user: {},
			},
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			currentBank: {},
			playerUserId: 0,
			accountAccessCode: 0,
			regularCOC: 0,
			regularAU: 0,
			playerUserId: "",
			playerRegularAU: 0,
			playerPromoAU: 0,
			transactionReport: {
				success: false,
				playerDisplayName: "",
				playerRegularAU: 0,
				playerPromoAU: 0,
				regularCOC: 0,
				transferRegularCOC: 0,
				transferPromoAU: 0,
			},
			casinoName: this.cashierState.casinoName,
			displayName: "",
			playerDisplayName: "",
			buyInOfferSelected: false,
			maxCashout: 0,
			cashoutAU: 0,
			displayCOC: this.systemCurrencyTool.formatFromAU(0, this.systemMinorOrFull),
		};
	},
	watch: {
		regularAU() {
			this.displayCOC = this.systemCurrencyTool.formatFromAU(Number(this.regularAU), this.systemMinorOrFull);
		},
	},
	async created() {
		this.eventBus.on("transferFundsConfig", () => {
			this.regularCOC = 0;
			this.transactionReport = {
				success: false,
				playerDisplayName: "",
				playerRegularAU: 0,
				playerPromoAU: 0,
				regularCOC: 0,
				transferRegularCOC: 0,
				transferPromoAU: 0,
			};
		});
		this.eventBus.on("closeKeypad", () => {
			this.inputConfig.inputField = null;
			this.displayCOC = this.systemCurrencyTool.formatFromAU(Number(this.regularAU), this.systemMinorOrFull);
		});
		onBeforeUnmount(() => {
			this.inputConfig.inputField = null;
			this.eventBus.off("transferFundsConfig");
			this.eventBus.off("closeKeypad");
		});
	},
	methods: {
		setMaxCashout(cashoutCOC, display = {}) {
			let playerRegularAU = this.cashierState.playerAccount.account.balance.regularAU;
			this.cashoutAU = this.systemCurrencyTool.toAUFromCurrency(cashoutCOC);
			this.maxCashout = playerRegularAU < this.cashoutAU ? playerRegularAU : this.cashoutAU;
			return this.systemCurrencyTool.formatFromAU(this.maxCashout, display);
		},
		async printTransactionReceipt(transaction) {
			let systemMinorOrFull = this.systemCurrencyTool.displayType.minorOrFull;
			let minorOrFull = this.inGameCurrencyTool.displayType.minorOrFull;
			let regAU = this.inGameCurrencyTool.formatFromAU(transaction.playerRegularAU, minorOrFull);
			let proAu = this.inGameCurrencyTool.formatFromAU(transaction.playerPromoAU, minorOrFull);
			let cash = this.systemCurrencyTool.formatCurrency(Math.abs(transaction.transferRegularCOC), systemMinorOrFull);

			// First element of printLines array; Initialize printer, set the code page to 16 (Windows-1252),
			// and set justify center for the header section.  The Windows-1252 encoding gives us access to
			// several currency symbols.  For this to work, we need to use the win1252Encoder later when
			// converting all this to bytes for the serial port.
			// https://en.wikipedia.org/wiki/Windows-1252
			let printLines = [`${this.textCodes.ESC}@${this.textCodes.ESC}t\x10${this.textCodes.ESC}a1`];

			// Header and site branding section
			printLines.push(this.textCodes.STARDIVIDER);
			printLines.push(this.textCodes.LF);
			printLines.push(this.setFontSize(this.casinoName, "\x11"));
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.STARDIVIDER);

			// Transaction section
			printLines.push(this.textCodes.LF);
			printLines.push(this.setFontSize("CASH OUT", "\x20"));
			printLines.push(new Date().toLocaleTimeString([], this.dateOptions));
			printLines.push(`${this.textCodes.ESC}a0`);
			printLines.push(this.textCodes.LF);
			printLines.push(this.formatSpacedLine("Player:", this.playerDisplayName));
			printLines.push(`${this.textCodes.LF}${this.textCodes.ESC}a1`);
			printLines.push(this.formatSpacedLine("Cash:", cash));

			// Player balance section
			printLines.push(`${this.textCodes.ESC}a1`);
			printLines.push(this.textCodes.LINEDIVIDER);
			printLines.push(`Resulting Account Balance${this.textCodes.ESC}a0`);
			printLines.push(this.formatSpacedLine("Regular:", regAU));
			printLines.push(this.formatSpacedLine("Promo:", proAu));

			// Footer section
			printLines.push(this.textCodes.LINEDIVIDER);
			printLines.push(`${this.textCodes.ESC}a1`);
			printLines.push("Thanks for playing!");
			printLines.push(`${this.textCodes.ESC}a0`);
			printLines.push(this.textCodes.STARDIVIDER);

			// Feed a couple of lines and cut paper
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.LF);
			printLines.push(`${this.textCodes.GS}VB${66}`);

			let printJob = printLines.join(`${this.textCodes.LF}`);

			this.eventBus.emit("printTicket", printJob);
		},
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.keypadInput = this.regularAU;
			this.inputConfig.locale = true;
		},
		async fundsTransfer() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			try {
				let _state = this.cashierState;
				this.playerUserId = this.cashierState.playerAccount?.user?.id || this.activeSession.playerAccount.user.id;
				this.accountAccessCode = this.cashierState.playerAccount?.accountAccessCode || this.activeSession.playerAccount.accountAccessCode;
				this.playerPhone = this.cashierState.playerAccount?.user?.phoneNumber || this.activeSession.playerAccount.user.phoneNumber;
				this.playerDisplayName = this.cashierState.playerAccount?.user?.displayName || this.activeSession.playerAccount.user.displayName;
				this.activeSession.accessToken = this.cashierState?.accessToken || this.activeSession.accessToken;
				this.currentBank = this.cashierState?.currentBank || this.activeSession.currentBank;

				this.regularCOC = this.systemCurrencyTool.toCurrencyFromAU(-Math.abs(Number(this.regularAU)));

				switch (true) {
					case isNaN(this.regularCOC):
						this.status.message = "Regular amount must have some value. Enter a zero if not adding any currency";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case !this.playerUserId:
						this.status.message = "Please open a player bank.";
						this.status.ok = false;
						this.status.userMustDismiss = true;
						this.eventBus.emit("updateStatus", this.status);
						this.eventBus.emit("setOpenPlayerCompoment");
						return;
					case !sharedScripts.isValidTransferValue(this.regularCOC):
						this.status.message = "Regular amount must be greater than -1,000,000,000 and less than 1,000,000,000";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case this.regularCOC > this.maxCashout:
						this.status.message = "Amount exceeds the maximum cashout limit";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case this.regularCOC === 0:
						this.status.message = "You must enter some amount to transfer from bank";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
				}

				let body = {
					CashierBankId: this.cashierState.currentBank.id,
					PlayerUserId: this.cashierState.playerAccount.user.id,
					AccountAccessCode: this.cashierState.playerAccount.accountAccessCode,
					FromPlayerCashCOC: this.regularCOC,
				};

				let requestUrl = new URL("/api/v1/cashier/player/funds", this.rabbitsfootHostUrl);
				let headerObj = new Headers();
				headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let request = new Request(requestUrl.toString(), {
					method: "POST",
					body: JSON.stringify(body),
					headers: headerObj,
				});

				this.serverStatus.serverBusy = true;

				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				var dataJson = await response.json();

				// 200 codes that are still some sort of fail for the action attempted
				if (fetchStatus.ok) {
					switch (dataJson.status) {
						case "BankNotFound":
							this.status.ok = false;
							this.status.message = `Bank #${this.cashierState.cashierBankId} Not Found`;
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InsufficientBankFunds":
							this.status.ok = false;
							this.status.message = "Insufficient Bank Funds";
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InsufficientPlayerFunds":
							this.status.ok = false;
							this.status.message = "Insufficient Player Funds";
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "InvalidAccountAccessCode":
							this.status.ok = false;
							this.status.message = "Invalid Account Access Code";
							this.eventBus.emit("updateStatus", this.status);
							this.eventBus.emit("requestRefreshPlayerCode");
							return false;
						case "InvalidBuyInOffer":
							this.status.ok = false;
							this.status.message = "Invalid Buy-In Offer";
							this.eventBus.emit("updateStatus", this.status);
							return false;
					}
				}

				this.cashierBankId = response?.cashierBank?.id;
				this.transactionReport.success = fetchStatus.ok;
				this.transactionReport.playerDisplayName = this.playerDisplayName;
				this.transactionReport.playerRegularAU = dataJson.playerAccount.balance.regularAU;
				this.transactionReport.playerPromoAU = dataJson.playerAccount.balance.promoAU;
				this.transactionReport.currentBalanceCOC = dataJson.cashierBank.currentBalanceCOC;
				this.transactionReport.transferRegularCOC = this.regularCOC;
				this.playerAccount.user.displayName = this.playerDisplayName;
				this.playerAccount.user.id = this.playerUserId;
				this.playerAccount.user.playerPhone = this.playerPhone;
				this.playerAccount.casinoId = this.cashierState.playerAccount.casinoId;
				this.playerAccount.account.fundsLocks = this.cashierState.playerAccount.fundsLocks;
				this.playerAccount.account.balance = dataJson.playerAccount.balance;
				// this.regularCOC = 0;
				this.regularAU = 0;
				_state.currentBank.currentBalanceCOC = dataJson.cashierBank.currentBalanceCOC;
				_state.playerAccount.account.balance = dataJson.playerAccount.balance;
				_state.playerAccount.account.fundsLocks = dataJson.playerAccount.fundsLocks;
				this.eventBus.emit("updateCashierState", _state);
				this.printTransactionReceipt(this.transactionReport);
				this.status.message = "Transfer Success";
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("closePlayerSession");
				this.serverStatus.serverBusy = false;
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverStatus.serverBusy = false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buy-in-offer {
	display: flex;
	flex-flow: row wrap;
}

.input-section div {
	flex-direction: column;
}

.input-section p {
	margin: auto 10px;
}

#fundsTransfer {
	padding-bottom: 15px;
	height: calc(100vh - 22em);
	overflow: hidden auto;
}

.fundsTransfer {
	width: 100%;
	margin: 30px auto;
}

.fundsTransfer tr {
	cursor: pointer;
}

#fundsTransfer::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#fundsTransfer::-webkit-scrollbar {
	width: 32px;
}

#fundsTransfer::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

#fundsTransfer .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.transfer,
.reprint {
	margin: 5px auto;
}
</style>
